<template>
  <div class="message-container">
    <p>
      This site is currently down for maintenance.<br>
      Sorry for the inconvenience.<br>
      You can still view your NEAR wallet and NFTs on<br>
      <a href="https://wallet.near.org/">https://wallet.near.org/</a>
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.message-container {
  text-align: center;
  min-height: 80vh;

  p {
    @include center-in-parent;
    width: 100%;
  }
}
</style>
